import { Switch } from "@headlessui/react"
import { Card, Tabs } from "flowbite-react"
import { useState } from "react"
import CustomSwitch from "../components/customSwitch"

export default function Settings(props) {
	const [enabled, setEnabled] = useState(false)
	const [enabled1, setEnabled1] = useState(false)
	const [enabled2, setEnabled2] = useState(false)
	const [enabled3, setEnabled3] = useState(false)
	const [enabled4, setEnabled4] = useState(false)
	const [enabled5, setEnabled5] = useState(false)
	const [enabled6, setEnabled6] = useState(false)
	const [enabled7, setEnabled7] = useState(false)
	const [enabled8, setEnabled8] = useState(false)
	const [enabled9, setEnabled9] = useState(false)

	const switchValue = {
		enabled: enabled,
		setEnabled: setEnabled,
	}

	const switchValue1 = {
		enabled: enabled1,
		setEnabled: setEnabled1,
	}

	const switchValue2 = {
		enabled: enabled2,
		setEnabled: setEnabled2,
	}

	const switchValue3 = {
		enabled: enabled3,
		setEnabled: setEnabled3,
	}

	const switchValue4 = {
		enabled: enabled4,
		setEnabled: setEnabled4,
	}

	const switchValue5 = {
		enabled: enabled5,
		setEnabled: setEnabled5,
	}

	const switchValue6 = {
		enabled: enabled6,
		setEnabled: setEnabled6,
	}

	const switchValue7 = {
		enabled: enabled7,
		setEnabled: setEnabled7,
	}

	const switchValue8 = {
		enabled: enabled8,
		setEnabled: setEnabled8,
	}

	const switchValue9 = {
		enabled: enabled9,
		setEnabled: setEnabled9,
	}

	return (
		<>
			<div className="p-4 bg-white block sm:flex items-center justify-between lg:mt-1.5 dark:bg-gray-900 dark:text-white">
				<div className="w-full mb-1">
					<div className="mb-4">
						<h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
							Settings
						</h1>
					</div>
				</div>
			</div>
			<div>
				<Tabs.Group aria-label="Tabs with icons" style="underline">
					<Tabs.Item title="Profile">
						<Card>
							<Switch.Group>
								<div className="flex  justify-between items-center">
									<CustomSwitch
										label="Enable Notification"
										value={switchValue}
									/>
								</div>
							</Switch.Group>
						</Card>
					</Tabs.Item>
					<Tabs.Item active={true} title="Dashboard">
						<Card>
							<Switch.Group>
								<div className="flex  justify-between flex-wrap gap-y-4">
									<CustomSwitch
										label="Enable Notification"
										value={switchValue}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue1}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue2}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue3}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue4}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue5}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue6}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue7}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue8}
									/>
									<CustomSwitch
										label="Enable Notification"
										value={switchValue9}
									/>
								</div>
							</Switch.Group>
						</Card>
					</Tabs.Item>
					<Tabs.Item title="Settings">
						<Card>
							<Switch.Group>
								<div className="flex  justify-between items-center">
									<CustomSwitch
										label="Enable Notification"
										value={switchValue}
									/>
								</div>
							</Switch.Group>
						</Card>
					</Tabs.Item>
					<Tabs.Item title="Contacts">
						<Card>
							<Switch.Group>
								<div className="flex  justify-between items-center">
									<CustomSwitch
										label="Enable Notification"
										value={switchValue}
									/>
								</div>
							</Switch.Group>
						</Card>
					</Tabs.Item>
					{/* <Tabs.Item disabled={true} title="Disabled">
						Disabled content
					</Tabs.Item> */}
				</Tabs.Group>
			</div>
			<div className="grid grid-cols-2 gap-3 wrap justify-center items-center"></div>
		</>
	)
}
