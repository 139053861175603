import { Carousel } from "flowbite-react"

export default function Card(props) {
	const { title, description, images } = props.project
	return (
		<>
			<div className="bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-xl  transition-all duration-500 dark:bg-gray-800 dark:border-gray-700">
				<div className="h-56">
					<Carousel className="">
						{images.map((img, index) => (
							<img
								key={index}
								className="w-full h-full rounded-t-lg"
								src={img}
							/>
						))}
					</Carousel>
				</div>
				{/* <a href={slug ? `/${slug}` : `/dashboard`}>
					<img
						className="rounded-t-lg"
						src={
							images[0] ||
							`https://images.unsplash.com/photo-1661875937707-677f09b89a3f?ixlib=rb-4.0.3&dl=ana-curcan-2yvwHSgQXIk-unsplash.jpg`
						}
						alt=""
					/>
				</a> */}

				<div className="p-5">
					<a href="#">
						<h3 className="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
							{title}
						</h3>
					</a>
					<p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
						{description}
					</p>
					<a
						href="#"
						className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
					>
						Read more
					</a>
				</div>
			</div>
		</>
	)
}
