import { Sidebar, Spinner } from "flowbite-react"
import { useRef, Suspense } from "react"
import { FaPlus } from "react-icons/fa"
import { Link, NavLink, Outlet, useLocation } from "react-router-dom"
import { routes } from "../routes/routes"

const SideBar = props => {
	const { collapsed, isSmallScreen } = props

	const mainRef = useRef()
	const { pathname } = useLocation()
	const paths = pathname
		.split("/")
		.slice(1)
		.map(path => path.charAt(0).toUpperCase() + path.slice(1))

	return (
		<>
			{/* Sidebar */}
			<div className="flex xs:flex-col-reverse h-full overflow-hidden bg-gray-50 dark:bg-gray-900">
				<Sidebar collapsed={collapsed}>
					<Sidebar.Items>
						<Sidebar.ItemGroup>
							{routes.map(({ href, icon, title }, key) => (
								<Sidebar.Item
									key={key}
									icon={icon}
									as={NavLink}
									to={href}
									active={href === pathname}
									onClick={() => mainRef.current?.scrollTo({ top: 0 })}
								>
									{title}
								</Sidebar.Item>
							))}
						</Sidebar.ItemGroup>
					</Sidebar.Items>
				</Sidebar>

				<main
					className="flex-1 p-4 xs:p-1 overflow-auto xs:w-full xs:h-full"
					ref={mainRef}
				>
					{/* Breadcrumb */}
					{isSmallScreen ? undefined : (
						<div className="sticky">
							<nav className="flex" aria-label="Breadcrumb">
								<ol className="inline-flex items-center space-x-1 md:space-x-3">
									<li className="inline-flex items-center">
										<a
											href="/"
											className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
										>
											<svg
												className="w-4 h-4 mr-2"
												fill="currentColor"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
											</svg>
											Home
										</a>
									</li>
									{paths.map((path, index) => (
										<li key={index}>
											<div className="flex items-center">
												<svg
													className="w-6 h-6 text-gray-400"
													fill="currentColor"
													viewBox="0 0 20 20"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
														clipRule="evenodd"
													></path>
												</svg>
												<Link
													to={paths.slice(0, index + 1).join("/")}
													className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
												>
													{path}
												</Link>
											</div>
										</li>
									))}
								</ol>
							</nav>
						</div>
					)}

					<Suspense
						fallback={
							<div className="flex items-center justify-center h-full">
								<Spinner />
							</div>
						}
					>
						<Outlet />
					</Suspense>
				</main>

				{/* Plus button to add new project */}
				{/* <Link to="/projects/add-new-project"> */}
				<div
					onClick={() => setOpenModal("create")} //eslint-disable-line
					className="flex items-center justify-center fixed bottom-[1%] right-[3%] xs:bottom-[7%] xs:right-[3%] md:bottom-[2%] md:right-[4%] xl:bottom-[3%] xl:right-[2.5%]  z-50  h-16 w-16 animate-bounce rounded-full drop-shadow-2xl shadow-md shadow-gray-400 transition-all duration-700 ease-in-out hover:-translate-y-2  bg-violet-700 hover:bg-violet-800 cursor-pointer hover:ring-2 "
				>
					<span className="animate-ping absolute inline-flex h-[3rem] w-[3rem] rounded-full bg-sky-400 opacity-75"></span>

					<FaPlus size={25} className="font-bold text-4xl text-white"></FaPlus>
				</div>
				{/* </Link> */}
			</div>
		</>
	)
}

export default SideBar
