import { createContext, useContext } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import useLocalStorage from "./useLocalStorage"

const fakeAuth = () =>
	new Promise(resolve => {
		setTimeout(() => resolve("2342f2f1d131rf12"), 250)
	})

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
	const location = useLocation()
	const navigate = useNavigate()
	const [token, setToken] = useLocalStorage("token", false)

	const login = async () => {
		const token = await fakeAuth()
		setToken(token)
		const origin = location.state?.from?.pathname || "/dashboard"
		navigate(origin, { replace: true })
	}

	const logout = async () => {
		setToken(false)
		navigate("/login", { replace: true })
	}

	const value = {
		token,
		login,
		logout,
	}
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
	return useContext(AuthContext)
}

export const ProtectedRoute = async ({ children }) => {
	const { token } = await useAuth()

	if (!token) {
		return <Navigate to="/login" />
	}

	return children
}
