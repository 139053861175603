const Theme = {
	theme: {
		sidebar: {
			base: "h-full drop-shadow-md  bg-inherit border-r border-gray-200 dark:border-0  xs:flex xs:flex-col xs:items-center xs:justify-center xs:h-16 xs:w-full xs:drop-shadow-t-md  ",
			inner:
				"h-full overflow-y-auto overflow-x-hidden rounded md:py-3  md:px-3 dark:bg-gray-800  xs:p-0 xs:bg-white xs:dark:drop-shadow-t-md xs:w-full xs:flex xs:flex-col  xs:justify-center",
			item: {
				base: "flex items-center justify-center p-2 text-base md:rounded-r-lg font-normal text-gray-900 hover:bg-gray-200 dark:text-white dark:hover:bg-gray-500  md:after:bg-[#e74c3c] md:after:content-[''] md:after:absolute md:after:right-full md:after:h-10 md:after:w-2 md:after:transition-transform md:after:ease-in-out md:after:duration-700 md:hover:after:translate-x-2   xs:p-5 xs:rounded-t-md xs:after:bg-[#e74c3c] xs:after:content-[''] xs:after:absolute xs:after:top-full xs:after:h-0 xs:after:w-[4rem] xs:hover:after:h-2  xs:after:transition-transform xs:after:ease-in-out xs:after:duration-700 xs:hover:after:-translate-y-2",
				active:
					"!bg-gray-300 dark:!bg-violet-500 dark:text-white  md:after:translate-x-2 xs:after:-translate-y-2 xs:after:!h-2 ",
				collapsed: {
					insideCollapse: "group w-full pl-8 transition duration-75",
					noIcon: "font-bold",
				},
				content: {
					base: "px-3 flex-1 whitespace-nowrap",
				},
			},
			itemGroup:
				"md:mt-4 md:space-y-2 md:border-t md:border-gray-200 md:pt-4 md:first:mt-0 md:first:border-t-0 md:first:pt-0 xs:w-full dark:border-gray-700 xs:flex xs:items-center xs:justify-between xs:w-full",
		},
		tooltip: {
			base: "absolute inline-block rounded-lg py-2 px-3 text-sm font-medium shadow-sm z-50",
		},
		navbar: {
			base: "bg-white px-2 py-2.5  drop-shadow-md dark:border-gray-700 dark:bg-gray-800 sm:px-4 border-b border-gray-200 dark:border-0",
		},
		avatar: {
			img: {
				on: "cursor-pointer ring-2 ring-violet-400 hover:ring-violet-700",
			},
		},
		label: {
			base: "text-sm font-bold",
			colors: {
				default: "text-gray-900 dark:text-gray-300",
				failure: "text-red-700 dark:text-red-500",
				info: "text-blue-500 dark:text-blue-600",
				success: "text-green-700 dark:text-green-500",
				warning: "text-yellow-500 dark:text-yellow-600",
				disabled: "opacity-50",
			},
		},
	},
}

export default Theme
