import { Link, Navigate } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import { FaFacebook, FaGithub, FaGoogle, FaTwitter } from "react-icons/fa"

export default function Login(props) {
	const { token, login } = useAuth()

	if (token) return <Navigate to="/dashboard" replace={true} />
	return (
		<>
			<div className="flex flex-col mx-auto md:h-screen justify-center items-center px-6 pt-8 pt:mt-0">
				{/* Brand */}
				<a
					href="/"
					className="text-2xl font-semibold flex justify-center items-center mb-8 lg:mb-10"
				>
					<img
						src="/android-chrome-512x512.png"
						className="h-10 mr-4"
						alt="khasni Logo"
					/>
					<span className="self-center text-2xl font-bold whitespace-nowrap">
						Khasni
					</span>
				</a>

				<div className="xl:p-0 lg:max-w-[1024px] lg:flex justify-center md:mt-0 shadow bg-white rounded-xl w-full">
					{/* side picture */}
					<div className="lg:flex w-2/3 hidden">
						<img
							className="rounded-l-lg "
							src="/images/authentication/create-account.jpg"
							alt=""
						/>
					</div>
					<div className="py-0 xs:py-8 sm:py-12 sm:p-8 p-6 w-full bg-white">
						<h2 className="md:text-3xl text-gray-900 font-bold text-2xl">
							Sign in to the platform
						</h2>
						<form
							action="#"
							className="flex flex-col space-y-8 mt-8 "
							onSubmit={event => {
								event.preventDefault()
							}}
						>
							<div className="flex flex-col space-y-1">
								<label
									htmlFor="email"
									className="text-xs font-medium block mb-2 text-gray-900"
								>
									Your email
								</label>
								<input
									type="email"
									placeholder="username@example.com"
									id="email"
									className="text-sm text-gray-900 p-2.5 bg-gray-50 transition duration-300 border border-gray-300 rounded-lg w-full block focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
								/>
							</div>
							<div className="flex flex-col space-y-1">
								<div className="flex items-center justify-between">
									<label
										htmlFor="password"
										className="text-xs font-medium block mb-2 text-gray-900"
									>
										Your Password
									</label>
									<a href="#" className="link">
										Forgot Password?
									</a>
								</div>
								<input
									type="password"
									id="password"
									placeholder="*************"
									className="text-sm text-gray-900 p-2.5 bg-gray-50 transition duration-300 border border-gray-300 rounded-lg w-full block focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
								/>
							</div>
							<div className="flex items-start gap-2">
								<input
									type="checkbox"
									id="remember"
									className="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
								/>
								<label
									htmlFor="remember"
									className="text-sm font-semibold text-gray-500"
								>
									Remember me
								</label>
							</div>
							<div>
								<button
									onClick={login}
									type="submit"
									className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
								>
									Log in
								</button>
							</div>
							<div className="flex flex-col gap-5">
								<span className="flex items-center justify-center gap-2">
									<span className="h-px bg-gray-400 w-14"></span>
									<span className="font-normal text-gray-500">
										or login with
									</span>
									<span className="h-px bg-gray-400 w-14"></span>
								</span>
								{/* Public accounts */}
								<div className="flex flex-wrap justify-center gap-2">
									<a
										href="#"
										className="sign-in-btn group border-blue-800  hover:bg-blue-800"
									>
										<span>
											<FaFacebook className="w-5 h-5 text-blue-800 fill-current group-hover:text-white" />
										</span>
										{/* <span className="text-gray-800 group-hover:text-white">
											Facebook10
										</span> */}
									</a>
									<a
										href="#"
										className="sign-in-btn group  border-red-800  hover:bg-red-800"
									>
										<span className="flex flex-col justify-center rounded-full">
											<FaGoogle className="w-5 h-4 text-red-800 fill-current group-hover:text-white" />
										</span>
										{/* <span className="text-red-800 group-hover:text-white">
											Google
										</span> */}
									</a>
									<a
										href="#"
										className="sign-in-btn group border-gray-800  hover:bg-gray-800"
									>
										<span>
											<FaGithub className="w-5 h-5 text-gray-800 fill-current group-hover:text-white" />
										</span>
										{/* <span className="text-gray-800 group-hover:text-white">
											Github
										</span> */}
									</a>
									<a
										href="#"
										className="sign-in-btn group border-blue-500  hover:bg-blue-500"
									>
										<span>
											<FaTwitter
												size={20}
												className="text-blue-500 group-hover:text-white"
											/>
										</span>
										{/* <span className="text-blue-500 group-hover:text-white">
											Twitter
										</span> */}
									</a>
								</div>

								{/* Termans and conditions */}
								<div>
									<p className="flex flex-col items-center justify-center text-center">
										<span>Don&apos;t have an account yet?</span>
										<Link to="/signup" className="link">
											Get Started!
										</Link>
									</p>
									<p className="mt-6 text-sm text-center text-gray-800">
										Read our{" "}
										<a href="#" className="link">
											terms
										</a>{" "}
										and{" "}
										<a href="#" className="link">
											conditions
										</a>
									</p>
								</div>
							</div>
						</form>
					</div>
					{/* </div> */}
				</div>
			</div>
		</>
	)
}
