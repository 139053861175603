import { useState } from "react"
import { Button, Checkbox, Modal, Table } from "flowbite-react"
import { FaDownload, FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa"
import userData from "../data/users.json"

const defaultUser = {
	name: "First_Name Last_Name",
	email: "username@example.com",
	phone: "+(213)649865425",
	position: "Worker",
	company: "Comapny Ltd.",
}

const EditUser = props => {
	const { openModal, user = defaultUser, setOpenModal } = props

	return (
		<>
			<Modal show={openModal === "edit"} onClose={() => setOpenModal("")}>
				<Modal.Header>
					<div className="flex items-start justify-between p-5 border-b rounded-t">
						<h3 className="text-xl font-semibold">Edit user</h3>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="p-6 space-y-6">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="first-name" className="input-label">
									First Name
								</label>
								<input
									type="text"
									name="first-name"
									id="first-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={user.name.split(" ")[0]}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="last-name" className="input-label">
									Last Name
								</label>
								<input
									type="text"
									name="last-name"
									id="last-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={user.name.split(" ")[1]}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="email" className="input-label">
									Email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={user.email}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="phone-number" className="input-label">
									Phone Number
								</label>
								<input
									type="number"
									name="phone-number"
									id="phone-number"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={
										user.phone ? user.phone : "Please add new phone number"
									}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="department" className="input-label">
									Position
								</label>
								<input
									type="text"
									name="department"
									id="department"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={
										user.position ? user.position : "Please add new position"
									}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="company" className="input-label">
									Company
								</label>
								<input
									type="number"
									name="company"
									id="company"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={
										user.company ? user.position : "Please add new company"
									}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="current-password" className="input-label">
									Current Password
								</label>
								<input
									type="password"
									name="current-password"
									id="current-password"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="••••••••"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="new-password" className="input-label">
									New Password
								</label>
								<input
									type="password"
									name="new-password"
									id="new-password"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="••••••••"
									required
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-center gap-4">
					<div className="text-center">
						<Button
							color=""
							outline
							gradientDuoTone="cyanToBlue"
							onClick={() => setOpenModal("")}
						>
							Save All
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const DeleteUser = props => {
	const { openModal, setOpenModal } = props

	return (
		<>
			<Modal
				show={openModal === "delete"}
				onClose={() => setOpenModal("")}
				size="lg"
			>
				<Modal.Body>
					<div className="text-center">
						<svg
							className="w-20 h-20 mx-auto text-red-600"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							></path>
						</svg>
						<h3 className="mt-5 mb-6 text-xl font-normal text-gray-500">
							Are you sure you want to delete this user?
						</h3>
						<div className="flex justify-center gap-4">
							<Button
								color=""
								outline
								gradientDuoTone="pinkToOrange"
								onClick={() => setOpenModal("")}
							>
								Yes, I&apos;m sure
							</Button>

							<Button
								color=""
								outline
								gradientDuoTone="cyanToBlue"
								onClick={() => setOpenModal("")}
							>
								No, cancel
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

const AddUser = props => {
	// const [newUser, setNewUser] = useState({})

	const { openModal, setOpenModal } = props

	return (
		<>
			<Modal show={openModal === "create"} onClose={() => setOpenModal("")}>
				<Modal.Header>
					<div className="flex items-start justify-between p-5 border-b rounded-t">
						<h3 className="text-xl font-semibold">Add new user</h3>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="p-6 space-y-6">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="first-name" className="input-label">
									First Name
								</label>
								<input
									type="text"
									name="first-name"
									id="first-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="e.g. Ahmed"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="last-name" className="input-label">
									Last Name
								</label>
								<input
									type="text"
									name="last-name"
									id="last-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="e.g. Merouane"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="email" className="input-label">
									Email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="example@company.com"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="phone-number" className="input-label">
									Phone Number
								</label>
								<input
									type="number"
									name="phone-number"
									id="phone-number"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="e.g. +(12)3456 789"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="department" className="input-label">
									Position
								</label>
								<input
									type="text"
									name="department"
									id="department"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="Developer"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="company" className="input-label">
									Company
								</label>
								<input
									type="text"
									name="company"
									id="company"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="Company Ltd."
									required
								/>
							</div>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer className="flex justify-center gap-4">
					<div className="text-center">
						<Button
							color=""
							outline
							gradientDuoTone="cyanToBlue"
							onClick={() => setOpenModal("")}
						>
							Save
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default function Users() {
	const [openModal, setOpenModal] = useState("")
	const [editedUser, setEditedUser] = useState(undefined)

	return (
		<div>
			{/* The top of the table */}
			<div className="p-4 bg-white block sm:flex items-center justify-between lg:mt-1.5 dark:bg-gray-900 dark:text-white">
				<div className="w-full mb-1">
					<div className="mb-4">
						<h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
							All Users
						</h1>
					</div>
					<div className="sm:flex">
						<div className="items-center hidden mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0">
							<label htmlFor="users-search" className="sr-only">
								Search
							</label>
							<div className="relative mt-1 lg:w-64 xl:w-96">
								<input
									type="text"
									name="email"
									id="users-search"
									className="z-0 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="Search for users"
								/>
							</div>
							<div className="flex pl-0 mt-3 space-x-1 sm:pl-2 sm:mt-0">
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
									</svg>
								</button>
							</div>
						</div>
						<div className="flex items-center ml-auto space-x-2 sm:space-x-3">
							<Button
								color="dark"
								outline
								size="xs"
								onClick={() => setOpenModal("create")}
							>
								<FaPlus className="mr-3" size={16} />
								Add user
							</Button>
							<Button color="dark" outline size="xs">
								<FaDownload className="mr-3" size={16} />
								Export
							</Button>
						</div>
					</div>
				</div>
			</div>

			{/* User table */}
			<div className="flex flex-col">
				<div className="overflow-x-auto">
					<div className="inline-block min-w-full align-middle">
						<div className="overflow-hidden shadow">
							<Table hoverable striped>
								<Table.Head>
									<Table.HeadCell className="!p-4">
										<Checkbox />
									</Table.HeadCell>
									<Table.HeadCell>Name</Table.HeadCell>
									<Table.HeadCell>Position</Table.HeadCell>
									<Table.HeadCell>Country</Table.HeadCell>
									<Table.HeadCell>Status</Table.HeadCell>
									<Table.HeadCell>
										<span className="sr-only">Editing purpose</span>
									</Table.HeadCell>
								</Table.Head>
								<Table.Body>
									{userData.map(user => (
										<Table.Row
											key={user.id}
											className="bg-white dark:border-gray-700 dark:bg-gray-800"
										>
											<Table.Cell className="!p-4">
												<Checkbox />
											</Table.Cell>

											<Table.Cell className="flex items-center p-4 mr-12 space-x-6 whitespace-nowrap lg:mr-0">
												<img
													className="w-10 h-10 rounded-full"
													src={"/images/users/" + user.avatar}
													alt={user.name + "avatar"}
												/>
												<div className="text-sm font-normal text-gray-500 ">
													<div className="text-base font-semibold text-gray-900 dark:text-slate-50">
														{user.name}
													</div>
													<div className="dark:text-gray-400">{user.email}</div>
												</div>
											</Table.Cell>

											<Table.Cell className="table-cell">
												{user.position}
											</Table.Cell>

											<Table.Cell className="table-cell">
												{user.country}
											</Table.Cell>

											<Table.Cell>
												<div className="flex items-center">
													{user.status === "Active" ? (
														<div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div>
													) : (
														<div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>
													)}
												</div>
											</Table.Cell>

											<Table.Cell className="table-cell">
												<button
													className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 dark:bg-outline dark:bg-gray-600 dark:hover:bg-gray-500 "
													onClick={() =>
														setOpenModal("edit") && setEditedUser(user)
													}
												>
													<FaEdit size={16} />
												</button>
												<button
													className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300"
													onClick={() =>
														setOpenModal("delete") && setEditedUser(user)
													}
												>
													<FaTrashAlt size={16} />
												</button>
											</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</div>
					</div>
				</div>
			</div>

			{/* Pagination */}
			<div className="bottom-0 right-0 items-center w-full p-4 bg-white border-t border-gray-200 sm:flex sm:justify-between">
				<div className="flex items-center mb-4 sm:mb-0">
					<button
						href="#"
						className="inline-flex justify-center p-1 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
					>
						<svg
							className="w-7 h-7"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
					<button
						href="#"
						className="inline-flex justify-center p-1 mr-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
					>
						<svg
							className="w-7 h-7"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
					<span className="text-sm font-normal text-gray-500">
						Showing <span className="font-semibold text-gray-900">1-20</span> of{" "}
						<span className="font-semibold text-gray-900">2290</span>
					</span>
				</div>
				<div className="flex items-center space-x-3">
					<Button outline size="xs">
						<svg
							className="w-5 h-5 mr-1 -ml-1"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
						Previous
					</Button>
					<Button outline size="xs">
						Next
						<svg
							className="w-5 h-5 ml-1 -mr-1"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</Button>
				</div>
			</div>

			{/* Modals */}
			<EditUser
				openModal={openModal}
				setOpenModal={setOpenModal}
				user={editedUser}
			/>
			<DeleteUser
				openModal={openModal}
				setOpenModal={setOpenModal}
				user={editedUser}
			/>
			<AddUser openModal={openModal} setOpenModal={setOpenModal} />
		</div>
	)
}
