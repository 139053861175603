import { Fragment } from "react"

import { Menu, Transition } from "@headlessui/react"
import {
	FaChartPie,
	FaSlidersH,
	FaBell,
	FaSignOutAlt,
	FaQuestionCircle,
} from "react-icons/fa"
import { Link } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"

export default function MyMenuDropdown({ children }) {
	const { logout } = useAuth()

	return (
		<div>
			<Menu as="div" className="relative inline-block text-left z-50">
				<div>
					<Menu.Button>{children}</Menu.Button>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-300 rounded-md bg-slate-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="px-1 py-1 ">
							<Menu.Item>
								{({ active }) => (
									<Link
										to="/dashboard"
										className={`${
											active ? "bg-violet-500 text-white" : "text-gray-900"
										} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
									>
										{active ? (
											<FaChartPie
												className="mr-2 h-4 w-4 "
												aria-hidden="true"
											/>
										) : (
											<FaChartPie
												className="mr-2 h-4 w-4 text-violet-500"
												aria-hidden="true"
											/>
										)}
										Dashboard
									</Link>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<Link
										to="/settings"
										className={`${
											active ? "bg-violet-500 text-white" : "text-gray-900"
										} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
									>
										{active ? (
											<FaSlidersH
												className="mr-2 h-4 w-4 "
												aria-hidden="true"
											/>
										) : (
											<FaSlidersH
												className="mr-2 h-4 w-4 text-violet-500"
												aria-hidden="true"
											/>
										)}
										Settings
									</Link>
								)}
							</Menu.Item>
						</div>
						<div className="px-1 py-1">
							<Menu.Item>
								{({ active }) => (
									<Link
										to="/notifications"
										className={`${
											active ? "bg-violet-500 text-white" : "text-gray-900"
										} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
									>
										{active ? (
											<FaBell className="mr-2 h-4 w-4" aria-hidden="true" />
										) : (
											<FaBell
												className="mr-2 h-4 w-4 text-violet-500"
												aria-hidden="true"
											/>
										)}
										Notifications
									</Link>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<Link
										to="/help"
										className={`${
											active ? "bg-violet-500 text-white" : "text-gray-900"
										} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
									>
										{active ? (
											<FaQuestionCircle
												className="mr-2 h-4 w-4"
												aria-hidden="true"
											/>
										) : (
											<FaQuestionCircle
												className="mr-2 h-4 w-4 text-violet-500"
												aria-hidden="true"
											/>
										)}
										Help
									</Link>
								)}
							</Menu.Item>
						</div>
						<div className="px-1 py-1">
							<Menu.Item>
								{({ active }) => (
									<Link
										onClick={logout}
										to="/login"
										className={`${
											active ? "bg-violet-500 text-white" : "text-gray-900"
										} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
									>
										{active ? (
											<FaSignOutAlt
												className="mr-2 h-4 w-4"
												aria-hidden="true"
											/>
										) : (
											<FaSignOutAlt
												className="mr-2 h-4 w-4 text-violet-500"
												aria-hidden="true"
											/>
										)}
										Log out
									</Link>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</div>
	)
}
