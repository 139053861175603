import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import "./style.css"
import App from "./App"

import { Flowbite } from "flowbite-react"
import Theme from "./theme"

const root = createRoot(document.getElementById("root"))

root.render(
	<StrictMode>
		<Flowbite theme={Theme}>
			<App />
		</Flowbite>
	</StrictMode>,
)
