import { Switch } from "@headlessui/react"
import useDarkMode from "../hooks/useDarkMode"

// import { BsFillMoonStarsFill } from "react-icons/bs"
import { BsFillMoonStarsFill } from "react-icons/bs"
import { HiSun } from "react-icons/hi"

export default function DarkModeSwitch(props) {
	const [enabled, setEnabled] = useDarkMode()

	return (
		<Switch
			as="div"
			checked={enabled}
			onChange={setEnabled}
			className={`${
				!enabled
					? "bg-violet-500 text-violet-200 focus-visible:ring-violet-600"
					: "bg-slate-700 text-slate-400 focus-visible:ring-slate-500"
			} relative inline-flex items-center py-1.5 px-2 rounded-full transition-colors duration-300 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus:outline-none`}
		>
			{!enabled ? (
				<span className="sr-only"> Enable dark mode</span>
			) : (
				<span className="sr-only"> Disable dark mode</span>
			)}

			<HiSun
				className={`transform transition-transform
										${!enabled ? "scale-0 duration-500" : "scale-100 duration-300"}`}
			/>

			<BsFillMoonStarsFill
				className={`ml-3.5 transform transition-transform
					${!enabled ? "scale-100 duration-300" : "scale-0 duration-500"}`}
			/>
			<span
				className={`absolute top-0.5 bg-white w-6 h-6 rounded-full flex items-center justify-center transition duration-500 transform ${
					!enabled ? "left-0.5" : "-left-1.5 translate-x-[2.625rem] "
				}`}
			>
				<HiSun
					className={`flex-none absolute left-1 transition duration-500 transform ${
						!enabled
							? "text-violet-500 opacity-100 scale-100"
							: "text-slate-900 opacity-0 scale-0"
					} `}
				/>

				<BsFillMoonStarsFill
					className={`flex-none -ml-0.5 transition duration-500 transform text-slate-700 ${
						!enabled ? "opacity-0 scale-0" : "ml-3.5 opacity-100 scale-100"
					}`}
				/>
			</span>
		</Switch>
	)
}
