import { BsGearFill } from "react-icons/bs"
import { FaBell, FaChartPie, FaProjectDiagram, FaUserAlt } from "react-icons/fa"
import { Navigate } from "react-router-dom"
import Dashboard from "../pages/dashboard"
import Projects from "../pages/projects_cards"
import Settings from "../pages/settings"
import Users from "../pages/users"

export const routes = [
	{
		title: "Dashboard",
		icon: () => <FaChartPie className="sidebar-icons" />,
		href: "/dashboard",
		component: <Dashboard />,
		group: true,
	},
	{
		title: "Users",
		icon: () => <FaUserAlt className="sidebar-icons" />,
		href: "/users",
		component: <Users />,
		group: false,
		card: {
			className: "w-40",
		},
	},
	{
		title: "Projects",
		icon: () => <FaProjectDiagram className="sidebar-icons" />,
		href: "/projects",
		component: <Projects />,
		group: false,
		card: {
			className: "w-40",
		},
	},
	{
		title: "Settings",
		icon: () => <BsGearFill className="sidebar-icons" />,
		href: "/settings",
		component: <Settings />,
		group: false,
		card: {
			className: "w-40",
		},
	},
	{
		title: "Notifications",
		icon: () => <FaBell className="sidebar-icons" />,
		href: "/notifications",
		// component: <AvatarPage />,
		group: false,
		card: {
			className: "w-40",
		},
	},
	{
		title: "Not Found",
		icon: () => <FaUserAlt className="sidebar-icons" />,
		href: "*",
		component: <Navigate to="/dashboard" />,
		group: false,
		card: {
			className: "w-40",
		},
	},
]
