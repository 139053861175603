import { useState } from "react"
import { Button, Modal } from "flowbite-react"
import { FaDownload, FaPlus } from "react-icons/fa"

import projectData from "../data/projects.json"

import Card from "../components/card"

const defaultProject = {
	id: 5,
	owner: "Angelo Moss",
	title: "What Construction Experts Want You to Know",
	slug: "what-construction-experts-want-you-to-know",
	skills: ["responsible", "logic", "nice"],
	description: "",
	images: [
		"https://picsum.photos/1024/800?random=600",
		"https://picsum.photos/1024/800?random=700",
	],
	category: "Construction",
	sub_category: "Construction",
	duration_type: "days",
	min_duration: 2,
	max_duration: 100,
	start_date: "Monday, 17 April 2023",
	min_budget: 69890,
	max_budget: 10000,
	questions: {},
	ansewers: {},
}

const EditProject = props => {
	const { openModal, project = defaultProject, setOpenModal } = props

	return (
		<>
			<Modal show={openModal === "edit"} onClose={() => setOpenModal("")}>
				<Modal.Header>
					<div className="flex items-start justify-between p-5 border-b rounded-t">
						<h3 className="text-xl font-semibold">Edit Project</h3>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="p-6 space-y-6">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="first-name" className="input-label">
									Title
								</label>
								<input
									type="text"
									name="first-name"
									id="first-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={project.title}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="last-name" className="input-label">
									Category
								</label>
								<input
									type="text"
									name="last-name"
									id="last-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={project.category}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="email" className="input-label">
									Min Duration
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={project.min_duration}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="phone-number" className="input-label">
									Max Duration
								</label>
								<input
									type="number"
									name="phone-number"
									id="phone-number"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={project.max_duration}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="department" className="input-label">
									Min Budget
								</label>
								<input
									type="text"
									name="department"
									id="department"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={project.min_budget}
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="company" className="input-label">
									Max Budget
								</label>
								<input
									type="number"
									name="company"
									id="company"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder={project.max_budget}
									required
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="flex justify-center gap-4">
					<div className="text-center">
						<Button
							color=""
							outline
							gradientDuoTone="cyanToBlue"
							onClick={() => setOpenModal("")}
						>
							Save All
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

const DeleteProject = props => {
	const { openModal, setOpenModal } = props

	return (
		<>
			<Modal
				show={openModal === "delete"}
				onClose={() => setOpenModal("")}
				size="lg"
			>
				<Modal.Body>
					<div className="text-center">
						<svg
							className="w-20 h-20 mx-auto text-red-600"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							></path>
						</svg>
						<h3 className="mt-5 mb-6 text-xl font-normal text-gray-500">
							Are you sure you want to delete this Project?
						</h3>
						<div className="flex justify-center gap-4">
							<Button
								color=""
								outline
								gradientDuoTone="pinkToOrange"
								onClick={() => setOpenModal("")}
							>
								Yes, I&apos;m sure
							</Button>

							<Button
								color=""
								outline
								gradientDuoTone="cyanToBlue"
								onClick={() => setOpenModal("")}
							>
								No, cancel
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export const AddProject = props => {
	// const [newProject, setNewProject] = useState({})

	const { openModal, setOpenModal } = props

	return (
		<>
			<Modal show={openModal === "create"} onClose={() => setOpenModal("")}>
				<Modal.Header>
					<div className="flex items-start justify-between p-5 border-b rounded-t">
						<h3 className="text-xl font-semibold">Add new Project</h3>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="p-6 space-y-6">
						<div className="grid grid-cols-6 gap-6">
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="first-name" className="input-label">
									First Name
								</label>
								<input
									type="text"
									name="first-name"
									id="first-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="e.g. Ahmed"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="last-name" className="input-label">
									Last Name
								</label>
								<input
									type="text"
									name="last-name"
									id="last-name"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="e.g. Merouane"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="email" className="input-label">
									Email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="example@company.com"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="phone-number" className="input-label">
									Phone Number
								</label>
								<input
									type="number"
									name="phone-number"
									id="phone-number"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="e.g. +(12)3456 789"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="department" className="input-label">
									Position
								</label>
								<input
									type="text"
									name="department"
									id="department"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="Developer"
									required
								/>
							</div>
							<div className="col-span-6 sm:col-span-3">
								<label htmlFor="company" className="input-label">
									Company
								</label>
								<input
									type="text"
									name="company"
									id="company"
									className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="Company Ltd."
									required
								/>
							</div>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer className="flex justify-center gap-4">
					<div className="text-center">
						<Button
							color=""
							outline
							gradientDuoTone="cyanToBlue"
							onClick={() => setOpenModal("")}
						>
							Save
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default function Projects() {
	const [projectList, setProjectList] = useState(projectData)
	const [openModal, setOpenModal] = useState("")
	const [projectFilter, setProjectFilter] = useState("")
	// eslint-disable-next-line
	const [editedProject, setEditedProject] = useState(undefined)

	const filterProject = value => {
		setProjectFilter(value)

		const listitems = value.replace(/\s\s+/g, " ").trim().split(" ")
		let newProjectList = projectData.filter(project =>
			listitems.every(
				element =>
					project.title.includes(element) ||
					project.skills.includes(element) ||
					project.description.includes(element),
			),
		)
		value === "" ? setProjectList(projectData) : setProjectList(newProjectList)
	}

	return (
		<div>
			{/* The top of the cards */}
			<div className="p-4 bg-slate-50 block sm:flex items-center justify-between lg:mt-1.5 dark:bg-gray-900 dark:text-white">
				<div className="w-full mb-1">
					<div className="mb-4">
						<h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
							All Projects
						</h1>
					</div>
					<div className="sm:flex">
						<div className="items-center hidden mb-3 sm:flex sm:divide-gray-100 sm:mb-0">
							<label htmlFor="Projects-search" className="sr-only">
								Search
							</label>
							<div className="relative mt-1 lg:w-64 xl:w-96">
								<input
									type="text"
									name="email"
									id="Projects-search"
									className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
									placeholder="Search for Projects"
									value={projectFilter}
									onChange={event => {
										filterProject(event.target.value)
									}}
								/>
							</div>
							<div className="flex pl-0 mt-3 space-x-1 sm:pl-2 sm:mt-0">
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
											clipRule="evenodd"
										></path>
									</svg>
								</button>
								<button href="#" className="search-icons">
									<svg
										className="w-6 h-6"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"></path>
									</svg>
								</button>
							</div>
						</div>
						<div className="flex items-center ml-auto space-x-2 sm:space-x-3">
							<Button
								color="dark"
								size="xs"
								onClick={() => setOpenModal("create")}
							>
								<FaPlus className="mr-3" size={16} />
								Add Project
							</Button>
							<Button color="dark" size="xs">
								<FaDownload className="mr-3" size={16} />
								Export
							</Button>
						</div>
					</div>
				</div>
			</div>

			{/* List projects in cards */}
			<div className="p-4 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
				{projectList.map((project, index) => (
					<div key={index}>
						<Card project={project} />
					</div>
				))}
			</div>

			<EditProject
				openModal={openModal}
				setOpenModal={setOpenModal}
				Project={editedProject}
			/>
			<DeleteProject
				openModal={openModal}
				setOpenModal={setOpenModal}
				Project={editedProject}
			/>
			<AddProject openModal={openModal} setOpenModal={setOpenModal} />
		</div>
	)
}
