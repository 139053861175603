import { Avatar, Navbar, Tooltip } from "flowbite-react"
import { FaIndent, FaOutdent, FaRegBell } from "react-icons/fa"
import DarkModeSwitch from "./darkModeSwitch"
import Notification from "./notification"
import MyMenuDropdown from "./profileDropdown"

const NavBar = props => {
	const { collapsed, isSmallScreen } = props

	const setCollapsed = state => {
		props.onChange(state)
	}

	return (
		<>
			{/* Navication top bar */}
			<Navbar
				fluid
				// className="border-b border-gray-200 dark:border-gray-600 bg-slate-50"
			>
				<div className="flex items-center">
					{/* Toggle Sidebar */}

					{isSmallScreen ? undefined : (
						<Tooltip content="Collapse sidebar" animation="duration-1000">
							<button
								className="top-navigation-icon"
								onClick={() => setCollapsed(!collapsed)}
							>
								<span className="sr-only"> Toggle sidebar</span>
								{collapsed ? <FaOutdent size={20} /> : <FaIndent size={20} />}
							</button>
						</Tooltip>
					)}

					{/* Brand */}
					<Navbar.Brand href="/" className="bg-slate-100">
						{/* <img
					src={logo}
					className="h-6 mr-3 sm:h-9"
					alt="Khasni Logo"
				/> */}
						<span
							className={`text-3xl font-bold dark:text-white ${
								collapsed ? "!ml-2" : "!ml-6"
							}`}
						>
							Khasni
						</span>
					</Navbar.Brand>
				</div>

				<div className="flex flex-wrap gap-2 mr-3">
					{/* Notification */}
					<Notification>
						<div className="top-navigation-icon">
							<span className="sr-only">View notifications</span>
							<FaRegBell size={24} />
						</div>
					</Notification>

					{/* Apps */}
					{/* <MyMenuDropdown>
				<div className="top-navigation-icon">
					<span className="sr-only">View Apps</span>
					<FaBuffer size={24} />
				</div>
			</MyMenuDropdown> */}

					{/* Dark mode toggle switch */}
					<div className="mt-1.5 cursor-pointer">
						<DarkModeSwitch />
					</div>

					{/* Profile */}
					<MyMenuDropdown>
						<Avatar
							alt="User's profile picture"
							img="/images/users/jese-leos.png"
							rounded
							bordered
							status="online"
							statusPosition="bottom-left"
						/>
					</MyMenuDropdown>
				</div>
			</Navbar>
		</>
	)
}

export default NavBar
