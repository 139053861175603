import { useEffect } from "react"
import useLocalStorage from "./useLocalStorage"

const useDarkMode = () => {
	const [enabled, setEnabled] = useLocalStorage("dark-theme")
	const [theme, setTheme] = useLocalStorage("theme")

	const isEnabled = typeof enabledState === "undefined" && enabled

	useEffect(() => {
		const className = "dark"
		const bodyClass = window.document.body.classList

		if (isEnabled) {
			bodyClass.add(className)
			setTheme("dark")
		} else {
			bodyClass.remove(className)
			setTheme("light")
		}
	}, [isEnabled, enabled, setEnabled, theme, setTheme])

	return [enabled, setEnabled]
}

export default useDarkMode
