import { useState } from "react"

const useLocalStorage = (key, initialValue) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(key)
			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			console.error(error) // eslint-disable-line
			return error
		}
	})

	const setValue = newValue => {
		try {
			window.localStorage.setItem(key, JSON.stringify(newValue))
		} catch (error) {
			console.error(error) // eslint-disable-line
			return error
		}
		setStoredValue(newValue)
	}
	return [storedValue, setValue]
}
export default useLocalStorage
