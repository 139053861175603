import { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import SideBar from "../containers/Sidebar"
import NavBar from "../containers/Navbar"

export default function MainLayout(props) {
	const [collapsed, setCollapsed] = useState(false)
	// const [openModal, setOpenModal] = useState("")
	const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" })

	useEffect(() => {
		isSmallScreen ? setCollapsed(true) : undefined
	}, [isSmallScreen, collapsed])

	return (
		<>
			{/* + Project */}
			{/* Application content */}
			<div className="flex flex-col w-full h-screen overflow-auto">
				<NavBar
					collapsed={collapsed}
					isSmallScreen={isSmallScreen}
					onChange={setCollapsed}
				/>
				<SideBar
					collapsed={collapsed}
					isSmallScreen={isSmallScreen}
					onChange={setCollapsed}
				/>
			</div>
		</>
	)
}
