import { useAuth } from "../hooks/useAuth"
import { FaGithub, FaTwitter } from "react-icons/fa"
import { Link } from "react-router-dom"

export default function Signup(props) {
	const { onLogin } = useAuth()
	return (
		<>
			<div className="flex items-center min-h-screen p-4 bg-gray-100 lg:justify-center">
				<div className="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
					<div className="p-4 py-6 text-white bg-blue-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
						<div className="my-3 text-4xl font-bold tracking-wider text-center">
							<a href="#">Khasni</a>
						</div>
						<p className="mt-6 font-normal text-center text-gray-300 md:mt-0">
							With the power of Khasni, you can now focus only on priorities and
							your life while leaving the work on your projets to skilled and
							tallented set of professionals!
						</p>
						<p className="flex flex-col items-center justify-center mt-10 text-center">
							<span>Already have an account?</span>
							<Link to="/login" className="underline">
								Login instead!
							</Link>
						</p>
					</div>
					<div className="p-5 bg-white md:flex-1">
						<h3 className="my-4 text-2xl font-semibold text-gray-700">
							Account Login
						</h3>
						<form action="#" className="flex flex-col space-y-5">
							<div className="flex flex-col space-y-1">
								<label
									htmlFor="email"
									className="text-sm font-semibold text-gray-500"
								>
									Email address
								</label>
								<input
									type="email"
									id="email"
									autoFocus
									className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
								/>
							</div>
							<div className="flex flex-col space-y-1">
								<div className="flex items-center justify-between">
									<label
										htmlFor="password"
										className="text-sm font-semibold text-gray-500"
									>
										Password
									</label>
								</div>
								<input
									type="password"
									id="password"
									className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
								/>
							</div>
							<div className="flex flex-col space-y-1">
								<div className="flex items-center justify-between">
									<label
										htmlFor="cpassword"
										className="text-sm font-semibold text-gray-500"
									>
										Confirm Password
									</label>
								</div>
								<input
									type="password"
									id="c"
									className="px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
								/>
							</div>
							<div className="flex items-center gap-2">
								<input
									type="checkbox"
									id="terms"
									className="w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200"
								/>
								<label
									htmlFor="terms"
									className="text-sm font-semibold text-gray-500"
								>
									I have red the{" "}
									<a href="#" className="underline">
										terms
									</a>{" "}
									and{" "}
									<a href="#" className="underline">
										conditions
									</a>
								</label>
							</div>
							<div>
								<button
									onClick={onLogin}
									type="submit"
									className="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
								>
									Sign up
								</button>
							</div>
							<div className="flex flex-col gap-5">
								<span className="flex items-center justify-center gap-2">
									<span className="h-px bg-gray-400 w-14"></span>
									<span className="font-normal text-gray-500">
										or signup with
									</span>
									<span className="h-px bg-gray-400 w-14"></span>
								</span>
								<div className="flex flex-wrap justify-center gap-1">
									<a
										href="#"
										className="sign-in-btn group border-gray-800  hover:bg-gray-800"
									>
										<span>
											<FaGithub className="w-5 h-5 text-gray-800 fill-current group-hover:text-white" />
										</span>
										<span className="text-gray-800 group-hover:text-white">
											Github
										</span>
									</a>
									<a
										href="#"
										className="sign-in-btn group border-blue-500  hover:bg-blue-500"
									>
										<span>
											<FaTwitter
												size={20}
												className="text-blue-500 group-hover:text-white"
											/>
										</span>
										<span className="text-blue-500 group-hover:text-white">
											Twitter
										</span>
									</a>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}
