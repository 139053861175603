import { Switch } from "@headlessui/react"

const CustomSwitch = props => {
	const { label } = props
	const { enabled, setEnabled } = props.value
	return (
		<>
			<Switch.Label className="inline-flex mr-4 dark:text-slate-50">
				{label}
			</Switch.Label>
			<Switch
				checked={enabled}
				onChange={setEnabled}
				className={`${
					enabled ? "bg-violet-600 " : "bg-gray-200 dark:bg-slate-400"
				} relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
			>
				<span
					className={`${
						enabled
							? "translate-x-[22px] bg-white"
							: "translate-x-[2px] bg-white dark:bg-slate-600"
					} inline-block h-5 w-5 transform rounded-full  transition-transform`}
				/>
			</Switch>
		</>
	)
}

export default CustomSwitch
