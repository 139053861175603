import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
	RouterProvider,
} from "react-router-dom"
import { AuthLayout, MainLayout, RrotectedLayout } from "./layouts"
import { Notification } from "./containers"
import { Dashboard, Login, Projects, Settings, Signup, Users } from "./pages"

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<AuthLayout />}>
			<Route element={<RrotectedLayout />}>
				<Route path="/" element={<MainLayout />}>
					<Route index element={<Navigate to="/dashboard" />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="projects" element={<Projects />} />
					<Route path="users" element={<Users />} />
					<Route path="notifications" element={<Notification />} />
					<Route path="settings" element={<Settings />} />
					<Route
						path="*"
						element={<Navigate to="/dashboard" replace={true} />}
					/>
				</Route>
			</Route>
			<Route path="/signup" element={<Signup />} />
			<Route path="/login" element={<Login />} />
		</Route>,
	),
	{},
)

export default function App() {
	return (
		<>
			<RouterProvider router={router} />
		</>
	)
}
